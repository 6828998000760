var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"background-picker"},[_vm._l((_vm.colorList),function(color,i){return _c('div',{key:i,staticClass:"color-box",class:{ colorChoice: _vm.style.bgColor === color },style:({ 'background-color': color }),attrs:{"id":'color-box' + i},on:{"click":function($event){return _vm.chooseBg('color', color)}}})}),(_vm.withImgBg)?_vm._l((_vm.imageList),function(image,j){return _c('div',{key:'a' + j,staticClass:"color-box",class:{
                colorChoice:
                    _vm.style.bgImg ===
                    ("url(" + (require('@/assets/img/' + image)) + ")"),
            },style:({
                'background-image': ("url(" + (require('@/assets/img/' +
                    image)) + ")"),
            }),attrs:{"id":'color-box' + 'a' + j},on:{"click":function($event){return _vm.chooseBg('image', image)}}})}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }