export const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult
    if (removedIndex === null && addedIndex === null) return arr

    const result = [...arr]
    let itemToAdd = payload

    if (removedIndex !== null) {
        // console.log('removedIndex !== null');
        itemToAdd = result.splice(removedIndex, 1)[0];

    }

    if (addedIndex !== null) {
        // console.log('addedIndex !== null');
        result.splice(addedIndex, 0, itemToAdd)

    }

    return result
}