var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workspace main-layout full"},[_c('section',[(_vm.atleastOneStarred)?_c('h4',{staticClass:"favorites"},[_vm._v("Favorites")]):_vm._e(),(_vm.atleastOneStarred)?_c('div',{staticClass:"board-previews-container"},[_vm._l((_vm.boards),function(board){return [(board.isStarred)?_c('board-preview',{key:board._id,attrs:{"board":board},on:{"atleastOneStarred":_vm.atleastOneStarred}}):_vm._e()]})],2):_vm._e(),_c('div',{staticClass:"board-previews-container"},[_c('div',{staticClass:"add-board-btn",on:{"click":_vm.openBoardModal}},[_c('span',[_vm._v("Create new board")])]),(_vm.isModalOpen)?_c('div',{staticClass:"modal-background",on:{"click":function($event){{
                        (_vm.isModalOpen = false), (_vm.openUnsplash = false);
                    }}}}):_vm._e(),(_vm.isModalOpen)?_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"add-board-modal",style:({
                        backgroundColor: _vm.boardStyle.bgColor,
                        backgroundImage:
                            'linear-gradient(rgb(0 0 0 / 27%), rgb(0 0 0 / 10%)) ,' +
                            _vm.boardStyle.bgImg,
                    })},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newBoard.title),expression:"newBoard.title"}],attrs:{"type":"text","placeholder":"Add board title"},domProps:{"value":(_vm.newBoard.title)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addBoard.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newBoard, "title", $event.target.value)}}})]),_c('background-picker',{on:{"chosenBg":_vm.chosenBg}}),_c('div',{staticClass:"modal-board-btns"},[_c('button',{attrs:{"disabled":!_vm.newBoard.title.length},on:{"click":_vm.addBoard}},[_vm._v(" Create board ")]),_c('button',{on:{"click":function($event){_vm.openUnsplash = !_vm.openUnsplash}}},[_c('span',{}),_vm._v("More ")])])],1),(_vm.openUnsplash)?_c('background-unsplash',{on:{"onSaveImg":_vm.onSaveImg}}):_vm._e()],1):_vm._e(),_vm._l((_vm.boards),function(board){return [(!board.isStarred)?_c('board-preview',{key:board._id,attrs:{"board":board},on:{"atleastOneStarred":_vm.atleastOneStarred}}):_vm._e()]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }